import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const Contact = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Kontakt potwierdzenie"
      lang={lang}
      translationEN="/en/contact-us/"
      translationDE="/de/kontakt/"
    >
      <SmallBanner title="Kontakt potwierdzenie" lang={lang} />
      <section className="contact-main">
        <div className="text-center">
          <p>Dziękujemy za przesłanie wiadomości!</p>
          <Link className="btn" to="/" data-hover="Strona głowna">
            Strona głowna
            <span className="btn-arrows"></span>
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
